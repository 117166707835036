import { apiFetch } from '@m/utils/http';
import { createEffect, createEvent } from 'effector';

import { ExistingSubscriptions } from 'types/api';

/**
 * events
 */
export const removeSubscriptionFromCart = createEvent();
export const resetSubscriptionAsyncStatuses = createEvent();

/**
 * effects
 */
export const addSubscriptionToCart = createEffect({
  handler: ({ planId }: { planId: number }) =>
    apiFetch(`/customer/subscription-plan/checkout/${planId}`, {
      method: 'POST',
    }),
});

export const getActiveSubscriptions = createEffect({
  handler: () => apiFetch<ExistingSubscriptions[]>('/customer/subscription'),
});

export const getSiteSubscriptions = createEffect({
  handler: ({ siteId }: { siteId: number }) =>
    apiFetch(`/customer/subscription/plan/site/${siteId}`),
});

export const getAvailableAndActiveSubscriptions = createEffect({
  handler: async ({
    latitude,
    longitude,
    maxDistanceMiles,
  }: {
    latitude: number;
    longitude: number;
    maxDistanceMiles: number;
  }) => {
    const response = await apiFetch('/subscription/search', {
      // @ts-ignore ts-migrate(2322) FIXME: Type '{ latitude: number; longitude: number; maxDi...
      params: {
        latitude,
        longitude,
        maxDistanceMiles,
      },
    });

    if (response && response.success) {
      return response;
    }
    throw new Error('Could not get available and active subscriptions');
  },
});

export const makePaymentForSubscription = createEffect({
  handler: async ({
    subscriptionId,
    paymentMethodId,
  }: {
    subscriptionId: number;
    paymentMethodId: number;
  }) => {
    const response = await apiFetch(`/customer/subscription/${subscriptionId}/purchase`, {
      method: 'POST',
      // @ts-ignore ts-migrate(2322) FIXME: Type '{ paymentMethodId: number; }' is not assigna...
      body: {
        paymentMethodId,
      },
    });

    if (response && response.success) {
      return response;
    }
    throw new Error('Failed to purchase for subscription');
  },
});

export const cancelSubscription = createEffect({
  handler: ({
    subscriptionId,
    subscriptionCancellationReasonId,
  }: {
    subscriptionId: number;
    subscriptionCancellationReasonId?: number;
  }) =>
    apiFetch(`/customer/subscription/${subscriptionId}`, {
      method: 'DELETE',
      body: {
        subscriptionCancellationReasonId,
      },
    }),
});
